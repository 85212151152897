import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {IAction, IAvailableEmail} from '../interfaces';
import {RouteEnum} from '../enums/route.enum';
import {ValueEnum} from '../enums/key.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router
  ) {}

  public openInUseBySnackbar(availableEmail: IAvailableEmail): void {
    if (availableEmail?.inUseBy && availableEmail?.unid) {
      const message: [string, string] = ['NOTIFICATIONS.EMAIL-IS-IN-USE-BY', availableEmail.inUseBy];
      const ref = this.openErrorSnackbar({message, action: 'STATUSES.OPEN'});
      ref?.onAction().subscribe(() => this.router.navigate([RouteEnum.Contacts, availableEmail.unid]));
    }
  }

  public openErrorSnackbar(params: ISnackBarParams): MatSnackBarRef<TextOnlySnackBar> | null {
    return this.openSnackbar({...params, isError: true});
  }

  public openSnackbar(params: ISnackBarParams | string): MatSnackBarRef<TextOnlySnackBar> | null {
    if (typeof params === 'string') {
      return this.snackbar.open(this.translate(params), '', this.getSnackbarConfig());
    } else {
      let message = params.message;

      if (Array.isArray(message)) {
        const translation = this.translate(message[0]);
        message = `${translation} ${message[1] || ''}`;
      } else if (message?.length) {
        message = this.translate(message);
      }

      if (message) {
        const action = params.action ? this.translate(params.action) : params.isError ? 'Ok' : '';
        const config = this.getSnackbarConfig(params.isError, params.config);
        return this.snackbar.open(message, action, config);
      } else {
        return null;
      }
    }
  }

  private getSnackbarConfig(isError?: boolean, config?: MatSnackBarConfig): MatSnackBarConfig {
    config = config || {};

    return {
      duration: isError ? 10000 : config?.duration || 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: isError ? 'error' : '',
      ...config
    };
  }

  public deleteNotification(errorMessage?: string): void {
    const message = `NOTIFICATIONS.${errorMessage ? 'ERROR-DELETING-DOCUMENT' : 'DOCUMENT-WAS-DELETED'}`;
    this.openSnackbar(`${this.translate(message)} ${errorMessage || ''}`);
  }

  public getActionMessage(data: IAction, key?: string): string {
    const getKey = (success: boolean, multiple: boolean) =>
      `NOTIFICATIONS.${multiple ? 'WERE' : 'WAS'}-${success ? '' : 'NOT-'}${key || 'CHANGED'}`;
    let message;

    if (data?.successCount && !data?.failureCount) {
      const key = getKey(true, data.successCount > 1);
      message = `${data.successCount} ${this.translate(key).toLowerCase()}`;
    } else if (data?.successCount && data?.failureCount) {
      const summary = data.successCount + data.failureCount;
      const key = getKey(true, summary > 1);
      message = `${data.successCount} ${this.translate('COMMON.OF').toLowerCase()} `;
      message += `${summary} ${this.translate(key).toLowerCase()}`;
    } else {
      const key = getKey(false, data?.failureCount > 1);
      message = `${data?.failureCount} ${this.translate(key).toLowerCase()}`;
    }

    return message;
  }

  public getResponseMessage(res: any, url?: string): string {
    let message: string;

    if (res?.data?.successList) {
      const deleteKey = url?.includes(ValueEnum.Delete) ? 'DELETED' : '';
      message = this.getActionMessage(res.data, deleteKey);
    } else if (res?.data?.unid || this.router.url.endsWith(RouteEnum.Settings)) {
      message = 'NOTIFICATIONS.SAVED';
    }

    return message;
  }

  public translate(key?: string): string {
    return key ? this.translateService.instant(key) : '';
  }
}

export interface ISnackBarParams {
  message?: string | [string, string];
  action?: string;
  duration?: number;
  config?: MatSnackBarConfig;
  isError?: boolean;
}
